var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","height":"100%"}},[_c('Form',{ref:"dataForm",attrs:{"model":_vm.formData,"label-width":80,"inline":""}},[_c('FormItem',{attrs:{"label":"外呼号码"}},[_c('Input',{model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})],1),_c('timeFilter',{ref:"timeFilter",attrs:{"formData":_vm.formData,"hiddenTime":true}}),_c('FormItem',[_c('Button',{staticStyle:{"margin-right":"20px","background-color":"#00A0E9"},attrs:{"type":"info"},on:{"click":function($event){return _vm.getData()}}},[_vm._v("查询")])],1)],1),_c('div',{staticStyle:{"height":"calc(100% - 130px)"}},[_c('GeminiScrollbar',{staticStyle:{"height":"100%","overflow":"auto"}},[_c('Table',{staticClass:"table-list",attrs:{"loading":_vm.loading,"columns":_vm.columns,"data":_vm.data,"no-data-text":"没有符合查询条件的数据"},scopedSlots:_vm._u([{key:"selectkey",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm._f("selectkeyStatus")(row.selectkey)))])]}},{key:"workstatus",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm._f("workstatusStatus")(row.workstatus)))])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm._f("statusStatus")(row.status)))])]}},{key:"downloadurl",fn:function(ref){
var row = ref.row;
return [_c('Button',{staticClass:"action-btn",attrs:{"type":"info","size":"small"},on:{"click":function($event){return _vm.changeAudio(row.downloadurl)}}},[_vm._v("播放音频")])]}}])},[_c('template',{slot:"content"},[_c('div',[_vm._v("气象局座席")])])],2)],1)],1),_c('Modal',{attrs:{"title":"","width":"450","mask-closable":false,"closable":false},model:{value:(_vm.showAudio),callback:function ($$v) {_vm.showAudio=$$v},expression:"showAudio"}},[_c('div',{staticStyle:{"text-align":"center"},attrs:{"id":"musicDiv"}},[_c('audio',{staticStyle:{"width":"330px"},attrs:{"id":"musicfx","src":_vm.audioUrl,"controls":"controls"}},[_c('source',{attrs:{"type":"audio/mpeg"}})])]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{staticStyle:{"margin-right":"8px"},on:{"click":function($event){return _vm.cancelAudio()}}},[_vm._v("取消")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }