

















































import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
import timeFilter from './timeFilter.vue'
const columns = [
    {
        title: '主叫号码',
        slot: 'content',
    },
    {
        title: '外呼号码',
        key: 'record_phone',
    },
    {
        title: '码归属地',
        key: 'kharea',
    },
    {
        title: '开始时间',
        key: 'starttime',
        width: 200,
    },
    {
        title: '结束时间',
        key: 'endtime',
        width: 200,
    },
    {
        title: '通话时间(s)',
        key: 'calltime',
    },
    {
        title: '录音文件',
        slot: 'downloadurl',
    },
    {
        title: '接听状态',
        slot: 'selectkey',
    },
    {
        title: '通话状态',
        slot: 'workstatus',
    },
    {
        title: '呼叫状态',
        slot: 'status',
    },
    {
        title: '记录时间',
        key: 'logdate',
    },
]
const workstatusList = ['等待', '呼出', '暂停', '完成', '取消'];
const statusList = ['等待', '提交', '空号', '忙音', '无应答', '通话', '语音'];
export default Vue.extend({
     components: {
        timeFilter,
    },
    data() {
        return {
            loading: false,
            pageNum: 1,
            pageSize: 10,
            columns,
            data: [],
            formData: {},
            showAudio: false,
            audioUrl: '',
        }
    },
    methods: {
        getData() {
            this.loading = true
            const { pageNum, pageSize } = this
            const timeFilterRef: any = this.$refs.timeFilter
            const formList = timeFilterRef.getData()
            const {beginTime, endTime} = formList
            formList.beginTime = moment(beginTime).format('YYYY-MM-DD')
            formList.endTime = moment(endTime).format('YYYY-MM-DD')
            this.formData = { ...this.formData, ...formList}
            const admincodes = this.$store.state.current.areaId
            const params = { ...this.formData, admincodes}
            request.get('/aofax/list', params)
            .then((data) => {
                this.data = data.data
                this.loading = false
            })
        },
        /* 改变pageNum */
        changPageNum(num: number) {
            this.pageNum = num
            this.getData()
        },
        changeAudio(url: string) {
            this.audioUrl = url
            this.showAudio = true
        },
        cancelAudio() {
            const myAudio: any = document.getElementById('musicfx');
            myAudio.pause();
            this.showAudio = false
        },
    },
    mounted() {
        this.getData()
    },
    filters: {
        selectkeyStatus(selectkey: string) {
            return selectkey === '989' ? '接听' : '挂掉或未接听';
        },
        workstatusStatus(workstatus: string) {
            return workstatusList[parseInt(workstatus, 10)]
        },
        statusStatus(status: string) {
            return statusList[parseInt(status, 10)];
        },
    },
})
